import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const BlogSection = () => {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    // Extract the first image from the blog content
    const extractFirstImage = (htmlContent) => {
        const div = document.createElement('div');
        div.innerHTML = htmlContent;
        const img = div.querySelector('img');
        return img ? img.src : null;
    };

    // Fetch blogs when the component mounts
    useEffect(() => {

        const size = 3;
        const API_KEY = "AIzaSyCfbgwSntGrudXFK_uewgNDD4ZODH7lsQY";
        const BLOG_ID = "1874005321459876876";
        const BASE_URL = `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts`;

        const fetchBlogs = async () => {
            const params = new URLSearchParams({
                key: API_KEY,
                maxResults: size.toString(),
            });

            try {
                const response = await fetch(`${BASE_URL}?${params}`);
                const data = await response.json();

                if (data && data.items) {
                    setBlogs(data.items.map(item => ({
                        id: item.id,
                        title: item.title,
                        content: item.content,
                        image: extractFirstImage(item.content) || '/default-blog-image.jpg',
                    })));
                } else {
                    console.error("Unexpected API response:", data);
                }
            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div id="blogs" className="container mt-3 pt-3">
            <div className="section-header mt-5">
                <h2>{"Blogs"}</h2>
            </div>
            <div className="row">
                {blogs.map((blog) => (
                    <div className="col-md-4" key={blog.id}>
                        <BlogCard
                            title={blog.title}
                            content={blog.content}
                            id={blog.id}
                            image={blog.image}
                        />
                    </div>
                ))}
            </div>
            <div
                className="flex-center"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <button
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        color: "#ff545a",
                        textDecoration: "none",
                        padding: "0.5rem 1rem",
                        border: "1px solid #ff545a",
                        marginTop: "2rem",
                    }}
                    onClick={() => navigate("/blogs")}
                >
                    Voir plus <FaArrowRight />
                </button>
            </div>
        </div>
    );
};

export default BlogSection;
