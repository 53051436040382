import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const BlogCard = (props) => {
  const navigate = useNavigate();
  const truncateString = (str, length) => {
    if (str?.length > length) {
      return str.slice(0, length) + '...';
    }
    return str;
  };

  const extractPlainText = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const getPostsDetail = ()=>{
     navigate(`/blogs/${props.id}`);
  }

  return (
    <div 
      onClick={getPostsDetail}
      className="single-blog-item" 
      style={{
        margin: '20px',
        border: '1px solid #ddd',
        borderRadius: '10px',
        overflow: 'hidden',
        cursor:"pointer",
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}
    >
      <div 
        className="single-blog-item-img" 
        style={{
          width: '100%',
          height: '200px',
          overflow: 'hidden',
          borderBottom: '1px solid #ddd'
        }}
      >
        <img
          src={props.image || "https://via.placeholder.com/300"}
          alt={`${props.title}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }}
        />
      </div>
      <div 
        className="single-blog-item-txt" 
        style={{
          padding: '15px',
          textAlign: 'left'
        }}
      >
        <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
          <Link 
            to={`/blogs/${props.id}`} 
            style={{
              textDecoration: 'none',
              color: '#333'
            }}
          >
            {truncateString(props.title, 100)}
          </Link>
        </h2>
        <p style={{ fontSize: '14px', color: '#555', lineHeight: '1.5' }}>
          {truncateString(extractPlainText(props.content), 100)}
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
