import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
    const { id } = useParams(); // `id` represents the postId from the URL
    const [blog, setBlog] = useState(null);
    const [error, setError] = useState(null);



    // Scroll to top when the component is mounted
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    // Fetch blog post details
    useEffect(() => {

        const API_KEY = "AIzaSyCfbgwSntGrudXFK_uewgNDD4ZODH7lsQY";
        const BLOG_ID = "1874005321459876876";
        const BASE_URL = `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts`;

        const fetchPostDetails = async () => {
            try {
                const response = await fetch(`${BASE_URL}/${id}?key=${API_KEY}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setBlog(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchPostDetails();
    }, [id]);

    if (error) {
        return <div style={{ textAlign: "center", color: "red" }}>Erreur: {error}</div>;
    }

    if (!blog) {
        return <div style={{ textAlign: "center", marginTop: "50px" }}>Chargement...</div>;
    }

    return (
        <div style={{ padding: "20px", maxWidth: "800px", margin: "35px auto", fontFamily: "Arial, sans-serif" }}>
            <h1 style={{ fontSize: "2.5em", marginBottom: "10px" }}>{blog.title}</h1>
            <p style={{ color: "#555", fontSize: "14px", marginBottom: "20px" }}>
                Publié le {new Date(blog.published).toLocaleDateString("fr-FR", { year: "numeric", month: "long", day: "numeric" })}
            </p>
            {blog.images && blog.images[0] && (
                <img
                    src={blog.images[0].url}
                    alt={blog.title}
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "10px",
                        marginBottom: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                />
            )}
            <div
                style={{ marginTop: "20px", fontSize: "1.2em", lineHeight: "1.8", color: "#333" }}
                dangerouslySetInnerHTML={{ __html: blog.content }}
            />
        </div>
    );
};

export default BlogDetails;
