import React, { useEffect, useState } from "react";
import './BlogList.css';
import BlogCard from "../../components/blog/BlogCard";

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [prevPageTokens, setPrevPageTokens] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);



    // Fetch blogs function


    // Fetch data on component load or page token change
    useEffect(() => {

        const API_KEY = "AIzaSyCfbgwSntGrudXFK_uewgNDD4ZODH7lsQY";
        const BLOG_ID = "1874005321459876876";
        const BASE_URL = `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts`;

        const fetchBlogs = async (pageToken = null) => {
            const params = new URLSearchParams({
                key: API_KEY,
                maxResults: "12",
                ...(pageToken && { pageToken }),
            });

            try {
                const response = await fetch(`${BASE_URL}?${params}`);
                return await response.json();
            } catch (error) {
                console.error("Error fetching from Blogger API:", error);
                throw error;
            }
        };

        const getBlogs = async () => {
            setIsLoading(true);
            try {
                const response = await fetchBlogs(nextPageToken);
                setBlogs(response.items.map(item => ({
                    id: item.id,
                    title: item.title,
                    content: item.content,
                    image: extractFirstImage(item.content) || '/default-blog-image.jpg',
                    url: item.url,
                })));
                setNextPageToken(response.nextPageToken || null);
            } catch (error) {
                console.error("Error fetching blogs:", error);
            } finally {
                setIsLoading(false);
            }
        };

        getBlogs();
    }, [nextPageToken]);

    // Helper function to extract the first image from HTML content
    const extractFirstImage = (htmlContent) => {
        const div = document.createElement('div');
        div.innerHTML = htmlContent;
        const img = div.querySelector('img');
        return img ? img.src : null;
    };

    const handleNextPage = () => {
        if (nextPageToken) {
            setPrevPageTokens(prev => [...prev, nextPageToken]);
            setCurrentPage(prev => prev + 1);
            setNextPageToken(nextPageToken);
        }
    };

    const handlePrevPage = () => {
        if (prevPageTokens.length > 0) {
            const newPrevTokens = [...prevPageTokens];
            const prevToken = newPrevTokens.pop();
            setPrevPageTokens(newPrevTokens);
            setNextPageToken(prevToken);
            setCurrentPage(prev => prev - 1);
        }
    };

    if (isLoading) {
        return (
            <div className="loading-spinner">
                Loading...
            </div>
        );
    }

    return (
        <div id="blogs" className="blog-list" style={{ marginTop: "35px" }}>
            <div className="container mt-3 pt-3">
                <div className="section-header">
                    <h2>Blogs</h2>
                </div>
                <div className="cards-container">
                    {blogs.map((blog) => (
                        <div className="col-md-4" key={blog.id}>
                            <BlogCard
                                title={blog.title}
                                content={blog.content}
                                id={blog.id}
                                image={blog.image}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="pagination-controls">
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="pagination-button"
                >
                    {"<"}
                </button>
                <span>Page {currentPage}</span>
                <button
                    onClick={handleNextPage}
                    disabled={!nextPageToken}
                    className="pagination-button"
                >
                    {">"}
                </button>
            </div>
        </div>
    );
};

export default BlogList;
